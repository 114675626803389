<template>
  <div>
    <!-- search input -->
    <section id="knowledge-base-search">
      
          <!-- form -->
          <validation-observer ref="searchForm" #default="{ invalid }">
            <b-form @submit="onSubmit" class="kb-search-input search-position">
              <validation-provider
                  #default="{ errors }"
                  name="clave"
                  rules="required">
                <b-input-group>
                  <b-form-input
                    id="searchbar"
                    class="text-input"
                    v-model="hashtag"
                    size="lg"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append>
                    <b-button :disabled="invalid" variant="outline-primary" @click="loadResults">
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small v-if="errors[0]" class="text-danger">{{$t('campaigns.required')}}</small>
              </validation-provider>
            </b-form>
          </validation-observer>
          <!-- form -->
    </section>
    <!--/ search input -->

    <section id="knowledge-base-content">

      <!-- content -->
      <b-row class="kb-search-content-info match-height">
        <b-col cols="12 mt-4">
          <instagram-media :perPage="10" :contents="results" :headerData="headerData" :key="change"/>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BInputGroup, BFormInput, BInputGroupAppend, BButton
} from 'bootstrap-vue'
import service from '@/services/others'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import InstagramMedia from '@/views/pages/profile/instagram/InstagramMedia.vue'
import utils from '../../../libs/utils'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    InstagramMedia
  },
  data () {
    return {
      hashtag : '',
      results : [],
      headerData: {
        avatar: '',
        is_verified: false
      },
      required,
      change: false,
      interval_loading: null
    }
  },
  beforeCreate() {
    service.getDefaultKeyword()
      .then(response => {
        this.hashtag = response.keyword
        this.getHashtags()
      })
  },
  created() {
  },
  destroyed() {
    const body = document.querySelector('div.app-content.content');
    body.removeAttribute('style');
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.$refs.searchForm.validate()
        .then(valid => {
          if (valid) {
            this.getHashtags()
          }
        })
    },
    filterContents(contents) {
      this.results = contents.filter(content => content.basic.caption && content.basic.caption.toLowerCase().includes(this.hashtag.toLowerCase()))
      this.change = !this.change

      clearInterval(this.interval_loading)
      this.$vs.loading.close()
    },
    getHashtags () {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: utils.getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: utils.getMotivationalPhrase(),
          });
        }
      }, 5000);
      this.results = []
      this.hashtag = this.hashtag.replace(/\s/g, '')
      this.hashtag = this.hashtag.replace(/[=_~¬!@#$%^&*(),.?":{}|<>]/g, '')
      service.getcontents()
        .then(response => {
          let contents = []
          response.results.forEach(res => {
            const new_contents = Object.values(res.contents.media)
            const newArr = new_contents.map(content => (
              {
                ...content,
                username: res.username,
                network: res.network,
                followers: res.followers,
                avatar: res.profile_url,
                is_verified: false
              }
            ))

            contents = contents.concat(newArr)
          });
          this.filterContents(contents)
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadResults () {
      this.$refs.searchForm.validate()
        .then(valid => {
          if (valid) {
            this.getHashtags()
          }
        })
    },
    openURL (code) {
      const url = `https://www.instagram.com/p/${code}`
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss">
.text-input{
  font-family: "Montserrat", Helvetica, Arial, serif;
}
.search-position{
  left:190px;
  z-index: 1;
  position: absolute;
  margin-top: 92px;
}
@media (max-width: 930px) {
  .search-position{
    left:44px;
    z-index: 1;
    position: absolute;
    margin-top: 92px;
  }
}
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
